import styled from '@emotion/styled'
import { FadeInUp } from 'app/components/Common/Animation/FadeInUp'
import { Image, Props as ImageProps } from 'app/components/Common/Image'
import React, { memo } from 'react'

interface ImagesProps {
  image?: ImageProps
}
export interface Props {
  images: ImagesProps[]
}

export const Mosaic = memo(function Mosaic({ images }: Props) {
  return (
    <Container>
      <ImageTL delay={0.1}>
        <StyledImage {...images[0].image} />
      </ImageTL>
      <ImageBL delay={0.25}>
        <StyledImage {...images[1].image} />
      </ImageBL>
      <ImageMain delay={0.1}>
        <StyledImage {...images[2].image} />
      </ImageMain>
      <ImageTR delay={0.2}>
        <StyledImage {...images[3].image} />
      </ImageTR>
      <ImageBR delay={0.3}>
        <StyledImage {...images[4].image} />
      </ImageBR>
    </Container>
  )
})

const Container = styled.section`
  position: relative;
  width: 100%;
  height: 40vw;

  @media (max-width: 991px) {
    margin: 60px 0;
  }
`

const ImageTL = styled(FadeInUp)`
  width: 25vw;
  aspect-ratio: 1.4;
  position: absolute;
  top: 0;
  left: 0;
`
const ImageBL = styled(FadeInUp)`
  width: 25vw;
  aspect-ratio: 1.4;
  position: absolute;
  bottom: 0;
  left: 0;
`
const ImageTR = styled(FadeInUp)`
  width: 25vw;
  aspect-ratio: 1.4;
  position: absolute;
  top: 0;
  right: 0;
`
const ImageBR = styled(FadeInUp)`
  width: 25vw;
  aspect-ratio: 1.4;
  position: absolute;
  bottom: 0;
  right: 0;
`
const ImageMain = styled(FadeInUp)`
  height: 100%;
  width: 32vw;
  position: absolute;
  top: 0;
  left: 50%;
  margin-left: -16vw;
`

const StyledImage = styled(Image)`
  width: 100%;
  height: 100%;
  display: block;
  overflow: hidden;

  img {
    display: block;
    width: 110%;
    height: auto;
  }
`
